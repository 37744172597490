import _ from 'lodash';
import { getDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';
import {
  orderTableHeaders,
  tradeTableHeaders,
  postitionTableHeaders,
  holdingTableHeaders,
} from './tableHeaders';
import {
  parseOrderDetails,
  parsePlaceOrder,
  parseMarginCalculator,
  parseModifyOrder
} from './parser';
import { wrappedFetch } from 'habitual-analytics/api/services/base';
import qs from 'qs';
import { REST_URLS } from 'habitual-analytics/api/resturls';

export const REQUEST_PHASE_URL = () => {
  // todo for mobile app
  return '';
};

const requestHeaders = () => {
  const { brokerExtras, brokerClientAccessToken } =
    getDynamicAppConfigs()?.brokerConfigs;
  return {
    'x-api-key': brokerExtras['api_key'],
    ...(brokerClientAccessToken && {
      Authorization: `Bearer ${brokerClientAccessToken}`
    }),
  };
};

const getRequestUri = (method, qs = '', path = '') => {
  const baseUrl = REST_URLS().BROKER_PROXY;

  const queryString = qs ? `&${qs}` : '';
  const pathString = path ? `&path=${path}` : '';

  return `${baseUrl}?broker=mncl&method=${method}${queryString}${pathString}`;
};



const isValidAccessToken = () => {
  const headers = requestHeaders();
  const parameters = {
    method: 'POST',
    headers,
  };

  return wrappedFetch(getRequestUri('validateUser'), parameters)
    .then((res) => res.json())
    .then(({ data }) => data?.status === 'true' ? true : { status: 'error' })
    .catch(() => {
      return { status: 'error' };
    });
};


const fetchOrderBook = () => {
  const headers = requestHeaders();
  const parameters = {
    method: 'POST',
    headers,
  };
  const queryString = qs.stringify({ offset: 1, limit: 200 });

  return wrappedFetch(getRequestUri('orderBook', queryString), parameters)
    .then((response) => response.json())
    .then((result) => ({
      orderBookDetails: _.compact(
        _.orderBy(parseOrderDetails(result?.data, 'order'), ['time'], ['desc'])
      ),
      headers: orderTableHeaders,
    }));
};

const fetchTradeBook = () => {
  const headers = requestHeaders();
  const parameters = {
    method: 'POST',
    headers
  };

  const queryString = qs.stringify({ offset: 1, limit: 100, order_id: '', order_ids: '' });

  return wrappedFetch(getRequestUri('tradeBook', queryString), parameters)
    .then((response) => response.json())
    .then((result) => {
      return {
        tradeBookDetails: _.compact(
          _.orderBy(
            parseOrderDetails(result?.data, 'trade'),
            ['time'],
            ['desc']
          )
        ),
        headers: tradeTableHeaders,
      };
    });
};

const fetchPositionBook = () => {
  const headers = requestHeaders();
  const parameters = {
    method: 'POST',
    headers
  };

  return wrappedFetch(getRequestUri('positionBook', 'intropStatus=1', 'all'), parameters)
    .then((response) => response.json())
    .then((result) => {
      return {
        positionBookDetails: _.compact(
          _.orderBy(
            parseOrderDetails(result?.data, 'position'),
            ['extraDetails.isOpenPosition'],
            ['desc']
          )
        ),
        headers: postitionTableHeaders,
      };
    });
};

const fetchHoldingBook = () => {
  const headers = requestHeaders();
  const parameters = {
    method: 'POST',
    headers
  };

  return wrappedFetch(getRequestUri('holdings'), parameters)
    .then((response) => response.json())
    .then((result) => {
      return {
        holdingBookDetails: _.compact(
          parseOrderDetails(result?.data, 'holdings')
        ),
        headers: holdingTableHeaders,
      };
    });
};

const placeOrder = async (placeOrderConfigs) => {
  const headers = requestHeaders();
  const formattedConfig = await Promise.all(
    _.map(placeOrderConfigs, async (orderConfig) => {
      const orderDetails = await parsePlaceOrder(orderConfig);
      const parameters = {
        method: 'POST',
        headers,
        body: JSON.stringify(orderDetails),
      };

      return wrappedFetch(getRequestUri('placeOrder'), parameters)
        .then((response) => response.json())
        .then((result) => result)
        .catch((e) => [e]);
    })
  );

  return _.chain(formattedConfig)
    .map(({ status, data }) => (status === 'success' ? data?.orderId : null))
    .filter((value) => value !== null)
    .value();
};

const cancelPlaceOrder = ({ orderNo, tradingSymbolObj }) => {
  const headers = requestHeaders();

  const exchangeSegement =
    tradingSymbolObj?.isEquity() ? 'NSE_EQ' : 'NSE_FO';

  const parameters = {
    method: 'POST',
    headers,
  };

  return wrappedFetch(
    getRequestUri('cancelPlaceOrder', null, `${exchangeSegement}/${orderNo}`),
    parameters
  ).then((response) => response.json());
};

const fetchMarginCalculator = async (orderConfigs) => {
  const marginCalculatorConfigs = await parseMarginCalculator(orderConfigs);

  const parameters = {
    method: 'POST',
    headers: requestHeaders(),
    body: JSON.stringify(marginCalculatorConfigs),
  };

  const { totalMarginRequired = 0, totalMarginAvailable = 0 } =
    await wrappedFetch(getRequestUri('marginCalculator'), parameters)
      .then((response) => response.json())
      .then(({ data }) => {
        return {
          totalMarginRequired:
            Number(_.round(data?.result?.ApproxMargin, 2)) || 0,
          totalMarginAvailable:
            Number(_.round(data?.result?.AvailableMargin, 2)) || 0,
        };
      })
      .catch(() => 0);

  return { totalMarginRequired, totalMarginAvailable };
};

const modifyPlaceOrder = async (placeOrderConfigs) => {
  const { brokerClientId } = getDynamicAppConfigs().brokerConfigs;
  const orderDetails = parseModifyOrder(placeOrderConfigs[0], brokerClientId);
  const exchange = placeOrderConfigs[0]?.tradingSymbolObj?.isEquity() ? 'NSE_EQ' : 'NSE_FO';
  const orderNo = placeOrderConfigs[0]?.orderNo;

  const parameters = {
    method: 'POST',
    headers: requestHeaders(),
    body: JSON.stringify(orderDetails),
  };
  return wrappedFetch(
    getRequestUri(
      'modifyOrder', '',
      `${exchange}/${orderNo}`
    ),
    parameters
  )
    .then((response) => response.json())
    .then(({ status, data }) =>
      status === 'success' ? [data?.oms_order_id] : null
    );
};

const sendOtp = (brokerClientId, apiKey) => {
  const requestBody = {
    user_id: brokerClientId,
    api_key: apiKey,
    source: 'WEBAPI',
  };

  const parameters = {
    method: 'POST',
    body: JSON.stringify(requestBody),
  };

  return wrappedFetch(getRequestUri('sendOtp'), parameters)
    .then((res) => res.ok)
    .catch(() => {
      return false;
    });
};
const validateOtp = (brokerClientId, apiKey, secondAuth) => {
  const requestBody = {
    user_id: brokerClientId,
    api_key: apiKey,
    source: 'WEBAPI',
    otp: secondAuth,
  };

  const parameters = {
    method: 'POST',
    body: JSON.stringify(requestBody),
  };

  return wrappedFetch(getRequestUri('validateUserOtp'), parameters)
    .then((res) => res.json())
    .then((result) => result)
    .catch(() => {
      return false;
    });
};

export {
  fetchOrderBook,
  fetchPositionBook,
  fetchTradeBook,
  fetchHoldingBook,
  placeOrder,
  isValidAccessToken,
  cancelPlaceOrder,
  fetchMarginCalculator,
  modifyPlaceOrder,
  sendOtp,
  validateOtp
};
