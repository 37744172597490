export const InstaConstants = (() => {
  const windowInstaConstants = window.instaConstants;
  return {
    get: (key) => {
      return _.get(windowInstaConstants, key, undefined);
    },
    isAdmin: () => {
      return windowInstaConstants.isAdmin;
    },
    getTheme: () => {
      return InstaConstants.get('themeVariant');
    },
    isDarkTheme: () => {
      return InstaConstants.getTheme() === 'dark';
    },
    shouldAllowPlanPage: () => {
      return windowInstaConstants.allowPlanPage;
    },
    allowBrokerStub: () => {
      return windowInstaConstants.allowBrokerStub;
    },
    brokerDomainConfigs: () => {
      return windowInstaConstants.brokerDomainConfigs;
    },
    internallyPlanPurchasedBrokers: () => {
      return windowInstaConstants.internallyPlanPurchasedBrokers;
    },
    isBrokerInternallyPurchased: (currentBrokerName) => {
      return _.includes(InstaConstants?.internallyPlanPurchasedBrokers(), currentBrokerName);
    },
    shouldDisplayPlanButton: (currentBrokerName) => {
      return (
        InstaConstants.shouldAllowPlanPage() &&
        !InstaConstants.isBrokerInternallyPurchased(currentBrokerName) &&
        _.includes(windowInstaConstants.planPageEnabledBrokers, currentBrokerName)
      );
    },
    getAll: () => {
      return windowInstaConstants;
    },
  };
})();