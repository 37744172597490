import React, { useEffect, useState } from 'react';
import ModalComponent from 'globals/components/ModalComponent';
import { getCurrentDate } from 'habitual-analytics/utils/datetime';
import moment from 'moment';
import { InstaConstants } from 'habitual-analytics/constants/instaConstants';

const RiskDisclosureModal = () => {
  const disclosureDisabledBrokers = InstaConstants.get('disclosureDisabledBrokers') || [];
  const { broker} = InstaConstants.get('userDetails') || {};
  const [showModal, setShowModal] = useState(false);
  const today = getCurrentDate().toISOString().slice(0, 10);
  const oneIndentaion = ' ';

  const sebiCircularLink = 'https://www.sebi.gov.in/reports-and-statistics/research/jan-2023/'
    + 'study-analysis-of-profit-and-loss-of-individual-traders-dealing-in-equity-fando-segment_67525.html';

  useEffect(() => {
    if (_.includes(disclosureDisabledBrokers, broker)) {
      return;
    }
    const lastModalDate = localStorage.getItem('riskDisclosureModalDate');

    if (_.isEmpty(lastModalDate)) {
      setShowModal(true);

      return;
    }

    const diffInDays = moment(today).diff(moment(lastModalDate), 'days');

    if (diffInDays >= 7) {
      setShowModal(true);

      return;
    }

  }, []);

  const handleCloseModal = () => {
    localStorage.setItem('riskDisclosureModalDate', today);
    setShowModal(false);
  };

  const modalProps = {
    title: 'Risk Disclosure',
    shouldShow: showModal,
    size: 'lg',
    onSaveid: 'btn-risl-disclosure',
    isShowCloseButton: false,
    onSave: handleCloseModal,
    okBtnTitle: 'I Understand',
    backdrop: 'static'
  };

  return (
    <ModalComponent {...modalProps}>
      <div className="container">
        <ul className="">
          <li className="mt-2">
            9 out of 10 individual traders in the equity Futures and Options Segment incurred net losses.
          </li>
          <li className="mt-2">
            On average, loss makers registered a net trading loss close to ₹50,000.
          </li>
          <li className="mt-2">
            Over and above the net trading losses incurred, loss makers
            {oneIndentaion}
            expended an additional 28% of net trading losses as transaction costs.
          </li>
          <li className="mt-2">
            Those making net trading profits incurred between 15% to 50% of such profits as transaction costs.
          </li>
        </ul>

        <p className="small text-muted mt-3">
          Source:
          {oneIndentaion}
          <a href={sebiCircularLink} target="_blank" rel="noreferrer">SEBI study</a>
          {oneIndentaion}
          dated January 25, 2023, on &quot;Analysis of Profit and Loss of Individual Traders
          {oneIndentaion}
          dealing in equity Futures and Options (F&O) Segment,&quot;wherein Aggregate Level
          {oneIndentaion}
          findings are based on annual Profit/Loss incurred by individual traders
          {oneIndentaion}
          in equity F&O during FY 2021-22.
        </p>
      </div>

    </ModalComponent>
  );
};

export default RiskDisclosureModal;
