import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { reactToastify, TOASTIFY_TYPES } from 'globals/utils/reactToastify';
import SingletonLiveTickerWrapper from 'components/SingletonLiveTickerWrapper/index';
import AppLayout from 'pages/AppLayout';
import { useDispatch, useSelector } from 'react-redux';
import AutoRefreshModal from 'components/AutoRefreshModal/index';
import RiskDisclosureModal from 'components/RiskDisclourseModal/index';
import { updateCommonConfigs } from 'redux_store/action/index';
import { getDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';
import { useNavigateSearch } from 'globals/hooks/navigateWithQueryParams';
import { getVerificationDetails } from './verifier';
import TelegramPopUp from '../TelegramPopUp/index';
import MobilePopup from '../MobilePopup/index';
import { OriginUtility } from '../../utils';
import { InstaConstants } from 'habitual-analytics/constants/instaConstants';

const propTypes = {};

const defaultProps = {};

const Authenticator = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { currentBrokerName } = useSelector((state) => state.commonConfigs);
  const navigate = useNavigateSearch();

  const [isUserLoggedIn, setUserLoggedIn] = useState(InstaConstants.get('isUserSignedIn'));
  const [isLoading, setIsLoading] = useState(true);

  const {
    isShowMobilePopup, mobilePopupShowBrokerList, isUserSignedIn, telegramPopUpNotEnabledBrokers
  } = InstaConstants?.getAll();

  useEffect(() => {
    (async () => {
      const verificationDetails = await getVerificationDetails();
      const { userRunType, historicalDateAndTime } = getDynamicAppConfigs()?.historicalConfigs || {};
      setUserLoggedIn(verificationDetails?.isUserLoggedIn);
      dispatch(updateCommonConfigs({ userRunType, historicalDateAndTime }));
      setIsLoading(false);
      document.body.classList.add(currentBrokerName || 'guest');
      if (verificationDetails?.message) {
        reactToastify(TOASTIFY_TYPES.ERROR, verificationDetails?.message);
      }
    })();
  }, []);

  const navigateToLogin = () => {
    OriginUtility.setOrigin();
    navigate('/login');
    // TODO is below reload necassary??
    if (!isUserSignedIn) window.location.reload();
  };

  const renderContent = (
    location.pathname === '/combined_premium' ? <Outlet /> : (
      <>
        <Outlet />
        <RiskDisclosureModal />
        <div className="popup-fixed">
          {isShowMobilePopup && _.includes(mobilePopupShowBrokerList, currentBrokerName) && <MobilePopup />}
          {!telegramPopUpNotEnabledBrokers.includes(currentBrokerName) && <TelegramPopUp />}
        </div>
        <AutoRefreshModal />
      </>
    )
  );

  const renderAppLayout = () => {
    return !isLoading && (
      <SingletonLiveTickerWrapper>
        <AppLayout>
          {renderContent}
        </AppLayout>
      </SingletonLiveTickerWrapper>
    );
  };

  return isUserLoggedIn ? (
    renderAppLayout()
  ) : (
    navigateToLogin()
  );
};

Authenticator.propTypes = propTypes;
Authenticator.defaultProps = defaultProps;

export default Authenticator;
