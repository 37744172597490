import _ from 'lodash';
import { holidayDates, weekendWorkingDays } from 'zeequant-constants';
import { ANALYTICS_MARKET_DATE_FORMAT, DATE_FORMAT } from './dateFormats';
import { getParsedDateObj } from 'habitual-analytics/utils/timezone';
import { getCurrentDate } from 'habitual-analytics/utils/datetime';
import { isLiveMode } from 'habitual-analytics/utils/index';

const DAYS_OF_WEEKS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const getValidMarketDate = (paramDate) => {
  const recursiveToGetValidDate = (actualDate) => {
    const actualMomentDate = getParsedDateObj(actualDate);
    const day = actualMomentDate.day();
    const dayName = DAYS_OF_WEEKS[day];

    if (
      _.includes(holidayDates, actualDate) ||
      (_.includes(['Saturday', 'Sunday'], dayName) &&
        !_.includes(weekendWorkingDays, actualDate))
    ) {
      return recursiveToGetValidDate(
        actualMomentDate.subtract(1, 'days').format(DATE_FORMAT)
      );
    }

    return actualDate;
  };

  return recursiveToGetValidDate(paramDate);
};

const getCurrentPrevDayMoment = () => {
  let validCurrentDayjs;
  const currentDayjs = getCurrentDate();
  const isLive = isLiveMode();
  const startMinute = isLive ? 16 : 15;
  // historic we can fetch 09:15:59, but live there is no data until those minute complete
  // so we are using 09:16:03 as start time and subtracting 1 minute to get the data

  const isCurrentDayHoliday =
    getValidMarketDate(currentDayjs.clone().format(DATE_FORMAT)) !==
    currentDayjs.format(DATE_FORMAT);

  if (isCurrentDayHoliday) {
    validCurrentDayjs = getParsedDateObj(
      getValidMarketDate(currentDayjs.clone().subtract(1, 'day'))
    )
      .set('hour', 15)
      .set('minute', 29);
  } else {
    const marketOpenTime = currentDayjs
      .clone()
      .set('hour', 9)
      .set('minute', startMinute)
      .set('second', 3);

    const marketCloseTime = currentDayjs
      .clone()
      .set('hour', 15)
      .set('minute', 29)
      .set('second', 59);

    const isBeforMarket = currentDayjs.clone().isBefore(marketOpenTime);
    const isAfterMarket = currentDayjs.clone().isAfter(marketCloseTime);

    const previousDayDate = currentDayjs
      .clone()
      .subtract(1, 'day')
      .format(DATE_FORMAT);

    validCurrentDayjs = isBeforMarket
      ? getParsedDateObj(getValidMarketDate(previousDayDate))
        .set('hour', 15)
        .set('minute', 29)
      : isAfterMarket
        ? currentDayjs.clone().set('hour', 15).set('minute', 29)
        : isLive
          ? currentDayjs.clone().subtract(1, 'minute')
          : currentDayjs.clone();
  }

  const validPreviousDay = getParsedDateObj(
    getValidMarketDate(
      validCurrentDayjs.clone().subtract(1, 'day').format(DATE_FORMAT)
    )
  )
    .set('hour', 15)
    .set('minute', 29)
    .format(ANALYTICS_MARKET_DATE_FORMAT);

  return {
    validCurrentDay: getParsedDateObj(validCurrentDayjs).format(
      ANALYTICS_MARKET_DATE_FORMAT
    ),
    validPreviousDay,
  };
};

export const getMarketTimingConfigs = _.throttle(() => {
  const { validCurrentDay, validPreviousDay } = getCurrentPrevDayMoment();

  return {
    marketStartTime: getParsedDateObj(validCurrentDay)
      .set('hour', 9)
      .set('minute', 15)
      .format(ANALYTICS_MARKET_DATE_FORMAT),

    marketEndTime: getParsedDateObj(validCurrentDay)
      .set('hour', 15)
      .set('minute', 29)
      .format(ANALYTICS_MARKET_DATE_FORMAT),

    marketCurrentTime: validCurrentDay,
    marketPrevDayEndTime: validPreviousDay,
    firstOiStartTime: getParsedDateObj(validCurrentDay)
      .set('hour', 9)
      .set('minute', 18)
      .format(ANALYTICS_MARKET_DATE_FORMAT),
    endOfTicksTablePrevDayEndTime: getParsedDateObj(
      getValidMarketDate(
        getParsedDateObj(validCurrentDay)
          .subtract(1, 'days')
          .format('YYYY-MM-DD')
      )
    )
      .set('hour', 15)
      .set('minute', 29)
      .format(ANALYTICS_MARKET_DATE_FORMAT),
  };
}, 500);
